import { AvisBloc } from '@pretto/zen/reveal/main/avisBloc/AvisBloc/AvisBloc'

import { useTrustScore } from '@pretto/website/src/utilities'

export type RowReviewsContainerProps = {
  description: string
  isColored: boolean
  suptitle?: string
  title: string
}

export const RowReviewsContainer = ({ description, suptitle, title }: RowReviewsContainerProps) => {
  const { score, numberOfReviews, reviews } = useTrustScore()

  const avisBlocProps = {
    description,
    hat: suptitle,
    reviews,
    title,
    trustpilotRateProps: {
      numberOfReviews,
      score,
    },
  }

  return <AvisBloc {...avisBlocProps} />
}
